import { useSubmit, Link, Form } from '@remix-run/react'
import { useRef } from 'react'
import { getUserImgSrc } from '#app/utils/misc.tsx'
import { APP_ROLE } from '#app/utils/roles.ts'
import { useOptionalUser } from '#app/utils/user.ts'
import { Button } from './ui/button'
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuPortal,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from './ui/dropdown-menu'
import { Icon } from './ui/icon'

export function UserDropdown() {
	const user = useOptionalUser()
	const isSuperAdmin = user?.roles.some(
		(role) => role.name === APP_ROLE.SUPER_ADMIN,
	)
	const isOrgUser = (user?.organizations.length ?? 0) > 0
	const isAdmin = isSuperAdmin || isOrgUser
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="ghost" className="size-8 rounded-full p-0">
					<Link
						to={`/guest`}
						// this is for progressive enhancement
						onClick={(e) => e.preventDefault()}
						className="flex size-8 items-center gap-2 rounded-full"
						aria-label="User Menu"
					>
						{user ? (
							user?.image ? (
								<img
									alt="hotel image"
									src={getUserImgSrc(user.image.id)}
									className="size-8 rounded-full border border-rose-200 bg-gradient-to-tr from-pink-100 from-20% via-yellow-200 via-60% to-blue-300 to-95%"
								/>
							) : (
								<div className="flex size-8 items-center justify-center rounded-full bg-gradient-to-tr from-pink-100 from-20% via-yellow-200 via-60% to-blue-300 to-95%">
									<Icon
										name="avatar"
										style={{ width: '32px', height: '32px' }}
										className="text-yellow-500"
									/>
								</div>
							)
						) : (
							<div className="flex size-8 items-center justify-center rounded-full bg-gradient-to-tr from-gray-200 from-20% via-slate-400 via-60% to-zinc-500 to-95%">
								<Icon
									name="avatar"
									style={{ width: '32px', height: '32px' }}
									className="text-white"
								/>
							</div>
						)}
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent
					align="end"
					className="shadow- min-w-48 border border-gray-50 px-0 py-3"
				>
					{user ? (
						<>
							{/* <DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/guest/messages`}>
									Messages
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/guest/trips`}>
									Trips
								</Link>
							</DropdownMenuItem> */}
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to="/hotels/list">
									List your hotel
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/reservation`}>
									Find your reservation
								</Link>
							</DropdownMenuItem>
						</>
					) : (
						<>
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm font-medium"
							>
								<Link prefetch="intent" to={`/login`}>
									Login
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/signup`}>
									Signup
								</Link>
							</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to="/hotels/list">
									List your hotel
								</Link>
							</DropdownMenuItem>
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/reservation`}>
									Find your reservation
								</Link>
							</DropdownMenuItem>
						</>
					)}

					{/* <DropdownMenuItem asChild className="rounded-none px-4 py-2 text-sm">
						<Link prefetch="intent" to={`#`}>
						List your hotel
						</Link>
						</DropdownMenuItem> */}

					{isAdmin && (
						<>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								asChild
								className="rounded-none px-4 py-2 text-sm"
							>
								<Link prefetch="intent" to={`/admin`}>
									Admin Dashboard
								</Link>
							</DropdownMenuItem>
						</>
					)}

					{user ? (
						<>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								asChild
								// this prevents the menu from closing before the form submission is completed
								onSelect={(event) => {
									event.preventDefault()
									submit(formRef.current)
								}}
								className="rounded-none px-4 py-2 text-sm"
							>
								<Form action="/logout" method="POST" ref={formRef}>
									<button type="submit">Logout</button>
								</Form>
							</DropdownMenuItem>
						</>
					) : null}
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
